@import 'src/styles/palette';

.timeline-step {
  position: relative;
  padding-bottom: 24px;
  padding-left: 20px;
  border-left: 3px solid $light-gray;
  margin-left: 9px;

  &:last-child {
    border-color: $white;
  }

  .title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 6px;
  }

  .description {
    font-size: 16px;
  }

  .download-link {
    margin-top: 10px;

    a {
      display: flex;
      align-items: center;
      color: $check-blue;

      .contract-icon {
        height: 24px;
      }

      .link {
        margin-left: 10px;
        cursor: pointer;
        height: 20px;
        font-size: 16px;
        text-decoration: none solid $check-blue;
        line-height: 20px;
      }
    }
  }

  .details {
    color: $check-blue;
    font-size: 14px;

    .detail-button {
      display: block;
      margin-top: 10px;

      .carret {
        margin-left: 5px;
        transform: rotate(90deg);
        &.open {
          transform: rotate(180deg);
        }
      }
    }

    .timeline-steps-details {
      margin-top: 20px;
    }
  }

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    border: 3px solid $light-gray;
    border-radius: 100%;
    box-sizing: border-box;
    position: absolute;
    left: -12px;
    background: $white;
  }

  &.active {
    &:not(:last-child) {
      border-color: $check-blue;
    }

    &::before {
      border-color: $check-blue;
    }

    &::after {
      content: '';
      width: 16px;
      height: 16px;
      border: 2px solid $white;
      border-radius: 100%;
      box-sizing: border-box;
      position: absolute;
      left: -10px;
      top: 2px;
      background: $check-blue;
    }
  }
}
