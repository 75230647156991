@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-blocks';

.sort-container {
  .sort {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    margin-bottom: 0;

    .sort-icon {
      margin-right: 6px;
      width: 18px;
      height: auto;
    }

    .sort-text {
      position: relative;
      line-height: 1.2;
      text-transform: capitalize;

      .subtext {
        font-size: 10px;
      }

      .indicator {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: $bubble-red;
        position: absolute;
        right: -10px;
        top: 0;
      }
    }
  }

  .sort-modal {
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    justify-content: center;

    @include desktop {
      @include cta-button-center;
    }

    .sort-modal-menu {
      background: $white;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      position: fixed;
      bottom: 0;
      width: 100%;
      padding: 24px 12px;
      transition: all 0.3s;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      max-width: 1220px;

      .close {
        position: absolute;
        top: -30px;
        right: 15px;
        cursor: pointer;
        font-size: 20px;
        color: $white;
      }

      .sort-modal-menu-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 900px;
      }
    }
  }
}
