@import 'src/styles/screen-types';
@import 'src/styles/palette';

.transfer-card {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  position: relative;
  gap: 9px;

  // C24 chips padding
  &.bank-card-padding {
    .bank.top {
      padding-top: 24px;
    }
  }

  &.desktop {
    gap: 100px;
    margin-top: 24px;

    &.bank-card-padding {
      .bank {
        padding-block: 24px;
      }
    }
  }
}

.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
  border: 1px solid $light-gray;
  border-radius: 30px;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  @include desktop {
    font-size: 24px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
  }
  .arrow-icon {
    max-height: 18px;
    max-width: 18px;
    @include desktop {
      max-height: 24px;
      max-width: 24px;
    }
  }
}
