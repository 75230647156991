@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-input-blocks';

.number-input {
  @include input-field-styles;

  .inner {
    input {
      padding-right: 16px;
    }
  }
}
