@import 'src/styles/palette';

.account-closing {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 16px;

  &.editable {
    cursor: pointer;
  }

  .closing-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .title {
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .info {
      font-size: 14px;
      color: $regular-gray;
    }
  }

  .carret-color {
    color: $nobel-gray;
  }
}
