@import 'src/styles/palette';

.timeline-step-detail {
  display: flex;
  align-items: center;
  padding: 5px;

  .icon-container {
    height: 22px;
    width: auto;
    display: flex;
    justify-content: center;

    .check {
      fill: $nobel-green;
    }

    .rectangle {
      color: $charcoal;
      margin-top: 7px;
    }

    .waiting {
      color: $charcoal;
    }
  }

  .details-container {
    margin-left: 10px;
    display: flex;
    flex-direction: column;

    .header {
      font-size: 16px;
      color: $charcoal;
      text-decoration: none solid $charcoal;
      font-weight: bold;
    }

    .description {
      font-size: 16px;
      color: $charcoal;
      text-decoration: none solid $charcoal;
      line-height: 20px;
      margin-top: 5px;
    }

    .link {
      cursor: pointer;
      width: 254px;
      height: 20px;
      font-size: 16px;
      color: $check-blue;
      text-decoration: none solid $check-blue;
      line-height: 20px;
      margin-top: 5px;
    }
  }
}
