@import 'src/styles/screen-types';
@import 'src/styles/palette';

.bank {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  border: 1px solid $light-gray;
  border-radius: 5px;
  flex: 1;
  gap: 16px;

  @include desktop {
    padding: 16px;
    gap: 16px;
  }

  .logo {
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $light-gray;
    border-radius: 38px;
    box-sizing: border-box;

    &.without-circle {
      border: none;
    }

    .logo-image {
      width: 40px;
      height: 40px;

      &.with-circle {
        width: auto;
        height: 24px;
      }
    }
  }

  .bank-data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .bank-name {
      font-size: 16px;
      color: $charcoal;
      text-decoration: none solid $charcoal;

      @include desktop {
        font-size: 18px;
        padding: 0;
      }
    }

    .bank-iban {
      font-size: 14px;
      color: $regular-gray;
      text-decoration: none solid $regular-gray;
      margin-top: 4px;

      @include desktop {
        font-size: 14px;
        margin-top: 5px;
      }
    }
  }

  &.top {
    flex-direction: column;
    padding: 10px 0px;
    align-items: center;
    gap: 10px;

    .bank-data {
      align-items: center;

      .bank-name {
        @include mobile {
          font-size: 14px;
          padding: 0px 10px;
        }
      }
      .bank-iban {
        @include mobile {
          font-size: 11px;
        }
      }
    }
  }

  // C24 chips padding
  &:has(.c24-account-chip) {
    padding-top: 24px;

    @include desktop {
      padding-block: 24px;
    }
  }
}
