@import 'src/styles/screen-types';

.joint-account-alert {
  .info-box {
    margin-top: 16px;

    @include desktop {
      margin-top: 24px;
    }
  }
}
