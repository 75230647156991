@import 'src/styles/palette';
@import 'src/styles/screen-types';
@import 'src/styles/mixins/common-input-blocks';

.date-input {
  margin-right: auto;
  width: 100%;

  .input-area {
    display: flex;
    width: 100%;
    gap: 8px;

    .number-input {
      &.day-input {
        flex: 1;
      }
      &.month-input {
        flex: 1;
      }
      &.year-input {
        flex: 2;
      }
    }
  }

  .error-text {
    @include error-text;
  }

  &.error {
    input {
      border-color: $error-red !important;
    }
  }
}
