@import 'src/styles/mixins/common-auto-complete';

.bank-search-item {
  @include auto-complete-item;

  .bank-logo {
    @include auto-complete-item-logo;

    &.with-circle {
      width: 40px;
      height: 40px;
      border: 1px solid $light-gray;
      border-radius: 100%;
      box-sizing: border-box;

      img,
      svg {
        width: auto;
        height: 24px;
      }
    }
  }

  .bank-info {
    @include auto-complete-item-details;
  }
}
