@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-input-blocks';
@import 'src/styles/mixins/common-detail-pages';
@import 'src/styles/mixins/common-blocks';

.close-account {
  .form-container {
    @include form-container;
    margin-top: 16px;
  }

  .intro-close-account {
    @include detail-pages-intro-container;
  }

  @include remove-sticky-on-input-focus;

  .cancel-section {
    @include details-page-cancel-section;
  }

  .account-closing-cancel-modal {
    @include confirmation-modal-content;
  }
}
