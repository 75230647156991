@import 'src/styles/palette';
@import 'src/styles/variables';
@import 'src/styles/screen-types';
@import 'src/styles/mixins/common-input-blocks';

.digital-signature {
  .signature-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info-icon {
      color: $light-gray;
      margin-left: 10px;
    }
    .title {
      margin-bottom: 16px;
      font-weight: bold;
    }
  }

  .row-1 {
    margin-top: 16px;
    p {
      color: $black;
    }
  }

  .poa {
    color: $check-blue;
    margin: 16px 0;
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;

    &.disabled {
      color: $regular-gray;
    }

    .file-icon {
      margin-right: 10px;
      height: 24px;
      width: auto;
      color: $check-blue;
    }

    .spinner {
      position: relative;
      width: 15px;
      height: 15px;
      border: 4px solid;
      margin: 0;
      margin-left: 10px;
      border-color: $check-blue;
      border-radius: 50%;
      &::before {
        content: '';
        position: absolute;
        width: 15px;
        left: -4px;
        top: -4px;
        height: 15px;
        border: 4px solid;
        margin: 0;
        border-color: $white transparent transparent;
        border-radius: 50%;
        animation: spin 1.8s linear infinite;
      }
    }
  }

  .signature-pad-container {
    margin: 16px 0;

    .signature-pad-wrapper {
      position: relative;
      height: 191px;
      border: 1px solid $light-gray;
      border-radius: 5px;

      &.error {
        border-color: $error-red;
      }

      .signature-pad {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }

      .circle-wrapper {
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 1;
        -moz-border-radius: 50px;
        -webkit-border-radius: 50px;
        border-radius: 50px;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .bin-icon {
          color: transparent;
          transition: color $transition-time;

          &.active {
            color: $regular-gray;
          }
        }
      }

      .dashed-spaced {
        height: 2px;
        background: linear-gradient(to right, $regular-gray 50%, rgba(255, 255, 255, 0) 0%);
        background-position: top;
        background-repeat: repeat-x;
        background-size: 7px;
        position: absolute;
        bottom: 18px;
        margin: 0 31px;
        width: calc(100% - 62px);
      }
    }

    & .error-message {
      @include error-text;
    }
  }
}
