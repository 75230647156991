//Breakpoints
$mobile-max-width: 767px;
$desktop-min-width: 768px;
$desktop-max-width: 1280px;

//Fonts
$body-font-desktop: Verdana, Helvetica, sans-serif;
$body-font-mobile: Arial, Helvetica, sans-serif;

$cta-font-family: Arial, Helvetica, sans-serif;
$cta-font-size: 16px;

$body-font-size-desktop: 14px;

$transition-time: 0.3s;
$transaction-margin-alignment: -16px;
