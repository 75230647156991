@import 'src/styles/palette';
@import 'src/styles/variables';

.review-state-chip {
  position: absolute;
  right: $transaction-margin-alignment;
  top: 0;
  padding: 2px 4px;
  background-color: rgba($success-green, 0.15);
  border-bottom-left-radius: 5px;

  font-size: 14px;
  line-height: 1;
  color: $charcoal;
}

.inner-border-container {
  :first-child {
    .review-state-chip {
      border-top-right-radius: 4px;
    }
  }
}
