@import 'src/styles/palette';
@import 'src/styles/screen-types';
@import 'src/styles/mixins/common-blocks';

.review-transaction {
  position: relative;
  @include border-bottom-extended;

  &.editable {
    cursor: pointer;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    padding-block: 16px;

    &:has(~ .edit-success) {
      padding-top: 16px;
      padding-bottom: 0;
    }
  }

  .data {
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: 60%;

    .name {
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .info {
      font-size: 14px;
      color: $regular-gray;
    }

    .amount {
      font-size: 14px;
      white-space: nowrap;
      color: $regular-gray;
    }
  }

  .extra-info {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    .info-wrapper {
      display: flex;
      align-items: center;
      gap: 16px;

      &.additional-padding {
        padding-right: 30px;
      }

      .date {
        text-align: right;
        color: $charcoal;
        font-size: 16px;
      }

      .carret-icon {
        color: $nobel-gray;
      }
    }
  }

  .edit-success {
    display: flex;
    gap: 4px;
    align-items: center;
    cursor: pointer;

    padding-top: 10px;
    padding-bottom: 16px;
    font-size: 14px;
    color: $nobel-green;

    .modified-icon {
      width: 16px;
      height: 16px;
    }
  }
}
