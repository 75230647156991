@import 'src/styles/palette';
@import 'src/styles/variables';
@import 'src/styles/screen-types';
@import 'src/styles/mixins/common-input-blocks';

.radio-button-group-container {
  flex: 1;

  .group-label {
    @include form-label;
  }

  .radio-button-group {
    padding-inline: 16px;

    background-color: $white;
    border-radius: 5px;
    border: 1px solid $light-gray;

    @include desktop {
      padding-inline: 24px;
      &:hover {
        border-color: $dark-gray;
      }
    }

    &.top-padding {
      padding-top: 11px;
    }

    &.no-border-layout {
      border: none;
      padding: 0;
    }

    &.error {
      border-color: $error-red;
    }

    .radio-buttons {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      gap: 20px;

      @include mobile {
        & > div:not(:last-child) {
          border-bottom: 1px solid $light-gray;
        }
      }

      @for $i from 1 through 6 {
        &.grid-cols-#{$i},
        &.horizontal.grid-cols-#{$i} {
          display: grid;
          grid-template-columns: repeat(#{$i}, 1fr);

          & > div:not(:last-child) {
            border-bottom: none;
          }

          & > div:not(:first-child):before {
            position: absolute;
            content: '';
            top: 6.5px;
            left: $transaction-margin-alignment;
            width: 1px;
            height: 32px;
            background-color: $light-gray;

            @include mobile {
              top: 10px;
              height: 36px;
            }
          }
        }

        // creates vertical layout on mobile if layout not specified
        @include mobile {
          &.grid-cols-#{$i} {
            display: block;

            & > div:not(:first-child):before {
              content: none;
            }
            & > div:not(:last-child) {
              border-bottom: 1px solid $light-gray;
            }
          }
        }
      }

      &.vertical {
        display: block;

        & > div:not(:last-child) {
          border-bottom: 1px solid $light-gray;
        }
        & > div:before {
          content: none;
        }
      }
    }
  }
}
