@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/mixins/common-input-blocks';

.select-input {
  .inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    .floating-label {
      position: absolute;
      pointer-events: none;
      top: 6px;
      left: 17px;
      font-size: 12px;
      opacity: 1;
      color: $regular-gray;
      transition: 0.2s ease all;

      @include mobile {
        font-size: 13px;
      }
    }

    select {
      font-size: 14px;
      border: 1px solid $light-gray;
      border-radius: 5px;
      padding: 16px 16px 0px 16px;
      background-color: $white;
      height: 47px;
      width: 100%;
      box-sizing: border-box;
      color: $charcoal;
      text-overflow: '';
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      transition: padding $transition-time;

      @include mobile {
        font-size: 18px;
        height: 58px;
      }

      @include desktop {
        &:hover {
          border-color: $dark-gray;
        }
      }

      &:not(.has-value) {
        padding-block: 8px;
        color: $regular-gray;
      }

      &:focus {
        border-color: $check-blue;
      }
    }

    .append-inner {
      height: 100%;
      display: flex;
      align-items: center;
      right: 16px;
      position: absolute;
      pointer-events: none;

      .dropdown-icon {
        margin-left: 20px;
        transform: rotate(180deg);
        width: 15px;
        height: 9px;
        transition: transform $transition-time;

        polygon {
          fill: $nobel-gray;
        }

        &.open {
          transform: none;
        }
      }
    }
  }

  .error-text {
    @include error-text;
  }

  &.error {
    select {
      border-color: $error-red;
    }
  }

  &.disabled {
    select,
    select:hover,
    select:focus {
      border-color: $light-gray;
      background-color: $cta-bg-gray;
      color: $dark-gray;
      cursor: default;
    }
    .floating-label {
      color: $regular-gray;
    }
  }
}
