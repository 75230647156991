@import 'src/styles/palette';
@import 'src/styles/screen-types';
@import 'src/styles/mixins/common-input-blocks';

.amount-input {
  @include input-field-styles;

  .error-text {
    width: calc(100vw - 32px) !important;
  }
}
