@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/variables';

.kontoumzug-section {
  background-color: $white;
  @include mobile {
    box-shadow: 0px 3px 6px #00000042;
    position: relative;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px;

    @include desktop {
      max-width: $desktop-max-width;
      margin-right: auto;
      margin-left: auto;
      padding: 40px 10px;
    }

    .col-1 {
      font-size: 18px;
      line-height: 21px;

      h2 {
        margin: 0;
        font-size: 24px;
        line-height: 28px;
        @include desktop {
          font-size: 30px;
          line-height: 36px;
        }
      }

      .text {
        margin-top: 24px;

        &.bottom-margin {
          margin-bottom: 24px;
        }
      }

      .row-1 {
        margin: 20px 0 25px;
        display: flex;
        align-items: center;

        .kontoumzug-img {
          height: 116px;
          margin-right: 11px;

          @include desktop {
            display: none;
          }
        }

        .list {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 16px;

          .list-item {
            display: flex;
            align-items: center;

            .check-icon {
              color: $check-blue;
              height: 15px;
              width: auto;
              margin-right: 8px;
            }
          }
        }
      }
    }
    .col-2 {
      margin-left: 20px;

      .row-1 {
        text-align: center;
        width: 254px;
        margin: 0 auto 20px auto;

        img {
          width: 100%;
        }
      }

      .row-2 {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 20px;

        img {
          height: 85px;
          width: auto;
        }
      }

      @include mobile {
        display: none;
      }
    }
  }

  button {
    @include desktop {
      margin: 0;
      width: 325px !important;
      max-width: none;

      &.cta-button {
        min-height: 60px;
        font-size: 18px;
      }
    }

    &.alternative {
      margin-top: 15px;

      @include desktop {
        min-height: 40px;
      }
    }
  }
}
