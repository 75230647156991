@import 'src/styles/variables';
@import 'src/styles/palette';
@import 'src/styles/screen-types';
@import 'src/styles/mixins/common-blocks';

.invalid-item {
  transition: border-color $transition-time;

  @include border-bottom-extended;

  .wrapper {
    display: flex;
    flex-grow: 1;
    gap: 16px;

    .details {
      padding-block: 16px;
      flex-grow: 1;
      cursor: pointer;
    }

    .checkbox {
      position: relative;
      top: 36px;
    }
    .information {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .name {
        font-size: 16px;
      }

      .name.highlight {
        color: $devils-gray;
      }

      .category {
        font-size: 14px;
        color: $regular-gray;
      }

      .payment-details {
        display: flex;
        gap: 4px;
        font-size: 14px;
        color: $regular-gray;

        .amount {
          white-space: nowrap;
        }
      }
    }

    .carret-color {
      color: $nobel-gray;
    }
  }

  &.highlight {
    position: relative;
    background-color: $alert-gray;
    margin-inline: $transaction-margin-alignment;
    padding-inline: 16px;

    .checkbox-container label::before {
      background-color: transparent;
      border-color: $nobel-gray;
    }
    .checkbox-container label::after {
      border-color: transparent;
    }
  }

  &:first-child {
    &.highlight {
      border-radius: 5px 5px 0 0;
    }
  }
  &:last-child {
    &.highlight {
      border-radius: 0 0 5px 5px;
    }
  }
  &:only-child {
    &.highlight {
      border-radius: 5px;
    }
  }
}
