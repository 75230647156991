@import 'src/styles/screen-types';
@import 'src/styles/palette';
@import 'src/styles/screen-types';

.advantage-section {
  margin-bottom: 8px;
  background-color: $white;

  @include desktop {
    margin-bottom: 40px;
  }

  .wrapper {
    padding: 16px 16px;

    @include desktop {
      max-width: $desktop-max-width;
      margin: 0 auto 0 auto;
      padding: 80px 0;
    }

    h2 {
      margin-top: 0;

      @include desktop {
        text-align: center;
        margin-bottom: 45px;
      }
    }

    .advantages {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;

      @include desktop {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .advantage {
        @include desktop {
          margin: auto;
          max-width: 570px;
        }

        .title {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .check-icon {
            color: $check-blue;
            height: 15px;
            width: auto;
            margin-right: 8px;
          }

          p {
            width: 100%;
            font-weight: bold;
            font-size: 18px;
            line-height: 20px;
          }
        }

        p {
          width: 100%;
          font-size: 14px;
          line-height: 20px;

          @include mobile {
            font-size: 16px;
          }
        }
      }
    }

    .logos {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;

      img {
        height: 85px;
      }

      @include desktop {
        display: none;
      }
    }
  }
}
