@import 'src/styles/palette';
@import 'src/styles/screen-types';

.c24-account-chip {
  position: absolute;
  top: 0;
  left: -1px;

  padding: 2px 8px;
  border-radius: 5px 0 5px 0;
  background-color: $light-gray;

  @include desktop {
    left: auto;
    right: -1px;
    border-radius: 0 5px 0 5px;
  }

  .chip-text {
    font-size: 14px;
    line-height: 16px;
    color: $charcoal;
  }
}
