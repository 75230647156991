@import 'src/styles/screen-types';
@import 'src/styles/mixins/common-blocks';

.thanks-page {
  @include desktop {
    margin-bottom: 48px;
  }

  .refresh-page-modal {
    @include confirmation-modal-content;
  }
}
