@mixin error-text {
  margin-top: 8px;
  color: $error-red;
  font-size: 12px;

  @include mobile {
    margin-top: 4px;
    font-size: 14px;
  }
}

@mixin form-label {
  font-size: 16px;
  margin-bottom: 16px;

  @include mobile {
    margin-bottom: 8px;
  }
}

@mixin form-container {
  padding: 0 16px;

  .description {
    font-size: 16px;
    margin-bottom: 44px;

    @include desktop {
      margin-bottom: 48px;
    }
  }

  .form-group {
    margin-bottom: 44px;

    @include desktop {
      margin-bottom: 48px;
    }

    .form-header {
      color: $charcoal;
      font-size: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }
    .form-label {
      @include form-label;
    }
    .form-input {
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      :last-child {
        margin-bottom: 0;
      }
    }
  }
}

@mixin switching-date-group {
  display: flex;
  flex-direction: column;

  .date-wrapper {
    width: 100%;

    .asap-row {
      width: 100%;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
    }
  }
  .switching-info {
    margin-top: 8px;
    align-self: baseline;
    font-size: 14px;
    color: $devils-gray;
  }
}

@mixin remove-sticky-on-input-focus($selected_class: '.action-buttons.sticky') {
  @include mobile {
    :has(input:focus:not([type='checkbox'])) #{$selected_class} {
      position: static;
    }
  }
}

@mixin input-field-styles {
  .inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    .floating-label {
      color: $regular-gray;
      position: absolute;
      pointer-events: none;
      left: 16px;
      top: 16px;
      font-size: 14px;
      transition: $transition-time ease all;

      @include mobile {
        font-size: 18px;
        top: 18px;
      }
    }

    input {
      height: 47px;
      font-size: 14px;
      border: 1px solid $light-gray;
      border-radius: 5px;
      padding: 16px 16px 0px 16px;
      background-color: $white;
      width: 100%;
      color: $charcoal;
      box-sizing: border-box;
      transition: 0.2s ease border-color;

      @include mobile {
        font-size: 18px;
        height: 58px;
        padding-right: 42px;
      }

      @include desktop {
        &:hover {
          border-color: $dark-gray;
        }
      }

      &:focus {
        border-color: $check-blue;
        background-color: $white;
      }

      &:focus ~ .floating-label,
      &.has-value ~ .floating-label {
        font-size: 12px;
        top: 6px;
        left: 17px;
        opacity: 1;

        @include mobile {
          font-size: 13px;
        }
      }

      &.has-value ~ .floating-label {
        color: $regular-gray;
        transition: none;
      }

      @include mobile {
        &:focus ~ .floating-label {
          color: $check-blue;
        }
      }
    }

    .append-inner {
      height: 100%;
      display: flex;
      align-items: center;
      right: 16px;
      position: absolute;
      color: $nobel-gray;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .error-text {
    @include error-text;
  }

  &.error {
    input {
      border-color: $error-red;
    }
  }

  &.disabled {
    input,
    input:hover,
    input:focus {
      border-color: $light-gray;
      background-color: $cta-bg-gray;
      color: $devils-gray;
      cursor: default;

      @include mobile {
        background-color: $cta-alter-disabled-gray;
      }
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
